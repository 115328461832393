<template>
  <sm-editable-item
    v-model="form"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave"
    @cancel="onCancel"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'SiteForCustomerEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      parentName: 'Customers',
      controllerName: 'Sites',
      pageHeader: 'Редактирование сайта клиентов',
      parentItem: null,
      initialForm: null,
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      customers: (state) => state.common.customers,
    }),

    id() {
      return +this.$route.params.id;
    },

    parentId() {
      return +this.$route.params.parentId;
    },

    isEqual() {
      return this.lodash.isEqual(this.form, this.initialForm);
    },

    breadcrumbs() {
      return [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Клиенты',
          route: { name: 'Customers' },
        },
        {
          text: `Редактирование клиента ${this.parentItem?.name}`,
          route: { name: 'CustomerEdit', params: { id: this.id } },
        },
        {
          text: 'Сайты клиентов',
          route: { name: 'SitesForCustomer', params: { id: this.parentId } },
        },
        {
          text: 'Редактирование сайта клиентов',
        },
      ];
    },

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название',
            },
            {
              type: 'select',
              key: 'customerId',
              label: 'Клиент',
              list: this.customers.data,
            },
            {
              type: 'text',
              key: 'url',
              label: 'Адрес сайта',
            },
            {
              type: 'text',
              key: 'created',
              label: 'Дата создания',
              disabled: true,
            },
            {
              type: 'checkbox',
              key: 'dontControlGisExport',
              label: 'Не контролировать выгрузку в ГИС ЖКХ',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;

    this.getItem({ name: this.parentName, id: this.parentId }).then(
      (result) => {
        this.parentItem = this.lodash.cloneDeep(result);
      }
    );

    const customers = this.getCommonList({ name: 'Customers' });

    Promise.all([customers]).then(() => {
      this.getItem({ name: this.controllerName, id: this.id })
        .then((result) => {
          this.initialForm = this.lodash.cloneDeep(result);
          this.form = this.lodash.cloneDeep(result);
        })
        .finally(() => {
          this.isLoadingPage = false;
        });
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),

    onSave() {
      // this.$root
      //   .$confirmModal({
      //     message: 'Отменить редактирование?',
      //     leftButtonText: 'Да',
      //     rightButtonText: 'Нет',
      //   })
      //   .then((response) => {
      //     if (response) {
      this.isLoadingSaveButton = true;
      this.updateItem({
        name: this.controllerName,
        payload: this.form,
      }).then((result) => {
        if (result.isSucceed) {
          this.$router.push({
            name: 'SitesForCustomer',
            params: { id: this.parentId },
          });
        }
      });
      //   }
      // });
    },

    onCancel() {
      if (this.isEqual) {
        this.$router.push({
          name: 'SitesForCustomer',
          params: { id: this.parentId },
        });
        return;
      }

      this.$root
        .$confirmModal({
          message: 'Отменить редактирование?',
          leftButtonText: 'Да',
          rightButtonText: 'Нет',
        })
        .then((response) => {
          if (response) {
            this.$router.push({
              name: 'SitesForCustomer',
              params: { id: this.parentId },
            });
          }
        });
    },
  },
};
</script>
